import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Login } from "../models/auth/login.model";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    restAPI = environment.restAPI + 'api/Auth/';

    constructor(private jwtHelper: JwtHelperService, private http: HttpClient){}

    login(login: Login){
        return this.http.post<any>(`${this.restAPI}login`, login);
    }

    public isAuthenticated(): boolean {
        const token = localStorage.getItem('token');

        return (token != null && !this.jwtHelper.isTokenExpired(token));
    }
}