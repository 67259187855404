import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class Interceptor implements HttpInterceptor { 
    intercept( request: HttpRequest<any>, next: HttpHandler ):                   
    Observable<HttpEvent<any>> {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');
        
        if (token != null)
        {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                    UserId: userId
                }
            });
        }

        if (location.protocol === 'http:') {
            const secureRequest = request.clone({
                url: request.url.replace(/^http:/, 'https:')
            
            });
            return next.handle(secureRequest);
         }
        
        return next.handle(request);
    }
}
