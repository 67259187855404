import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(public auth: AuthService, public router: Router){}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        // Captura a URL completa da solicitação
        const url = state.url;
        
        if (!this.auth.isAuthenticated()) {
            // Armazenar a URL que o usuário tentou acessar
            localStorage.setItem('redirectUrl', url);
            this.router.navigate(['login']);
            return false;
        }

        return true;
    }
}